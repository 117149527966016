import React, { FC } from 'react';

import { Survey } from './types/Survey';

interface Props {
  survey: Survey | null;
}

const Footer: FC<Props> = ({ survey }) => {
  const customLogo = survey?.customLogo ?? null;

  return (
    <div className="footer-container">
      <div className="footer">
        {customLogo ? (
          <img src={customLogo} className="footer-logo" alt="" />
        ) : (
          <a href="https://eee.do/" target="blank">
            <img
              className="footer-logo"
              src="/images/eeedo_logo_white_500x100-250x50@2x.png"
              alt="eeedo"
            />
          </a>
        )}
        <p className="copyright">copyright &copy; {new Date().getFullYear()} eeedo</p>
      </div>
    </div>
  );
};

export default Footer;
